import {Component} from '@angular/core';
import {AuthUser} from './User';
import {AuthService} from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent {
  authUser: AuthUser = new AuthUser();

  constructor(public authService: AuthService) {
  }
}
