export class User {
  id: number;
  login: string;
  name: string;
  group: string[];
  permission: string[] = [];
  auth: boolean;
}

export class AuthUser {
  login: string;
  pass: string;
}
