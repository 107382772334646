import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {CustomInterceptor} from './custom-interceptor';

import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSidenavModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';

import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth.guard';
import {FilesService} from './files.service';
import {RequestService} from './request.service';

import {AppComponent} from './app.component';
import {AuthComponent} from './auth/auth.component';
import {FileListComponent} from './file-list/file-list.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {FilesValidationComponent} from './files-validation/files-validation.component';
import {FileErrorsModalComponent} from './file-list/file-errors-modal/file-errors-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    FileListComponent,
    MainMenuComponent,
    FilesValidationComponent,
    FileErrorsModalComponent
  ],
  entryComponents: [
    FileErrorsModalComponent
  ],
  imports: [
    FormsModule,

    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule,

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    CookieService,
    FilesService,
    RequestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
