import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
      url: '/api' + request.url
      // url: 'https://etools.st-mobi.com/api' + request.url
      // url: 'http://localhost:8080' + request.url
    });
    return next.handle(request);
  }
}
