import {Component, OnInit} from '@angular/core';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(public authService: AuthService) {
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      console.log('reading user data from service');
      this.authService.getUser();
      return;
    }
  }
}
