import {Injectable} from '@angular/core';
import {AuthUser, User} from './User';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {ResponseJson} from '../response';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user: User = new User();

  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router) {
  }

  signIn(user: AuthUser) {
    console.log('sign in');
    console.log(user);
    const expires = 480 / 1440; // Соотношение в минутах 8 часов к суткам, иначе пришлось бы задавать время в днях (или по дату)
    this.http.post('/user/login', {'login': user.login, 'pass': user.pass})
      .subscribe((res: ResponseJson) => {
        this.cookieService.set('token', res.data.token, expires);

        // this.getUser();

        res.data.token = null;
        this.user = res.data;

        this.router.navigate(['/validation']);
      }, res => {
        console.error('user signIn: ', res.error);
        this.router.navigate(['/auth']);
      });
  }

  getUser() {
    console.log('get user');
    this.http.get('/user').subscribe((res: ResponseJson) => {
        this.user = res.data;
        this.user.auth = true;
        console.log('user getting');
      },
      res => {
        console.error('user getting: ', res.error);
        this.signOut();
        this.router.navigate(['/auth']);
      });
  }

  signOut() {
    console.log('sign out');
    this.cookieService.delete('token');
    this.user = new User();
    this.router.navigate(['/auth']);
    window.location.reload();
  }

  isLoggedIn() {
    return this.cookieService.check('token');
  }
}
