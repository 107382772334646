import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {RequestService} from './request.service';

export class FilesImportValidation {
  position: number;
  name: string;
  size: number;
  status: string;
  err: string[];
  file: File;
}

@Injectable({
  providedIn: 'root'
})

export class FilesService {
  private data: FilesImportValidation[] = [];
  filesDataStream = new Subject<FilesImportValidation[]>();
  incr = 0;

  get dataStream() {
    return this.filesDataStream.asObservable();
  }

  constructor(private request: RequestService) {
  }

  getData(): FilesImportValidation[] {
    return this.data;
  }

  addFileToList(f: File) {
    const position = this.data.length;

    this.data.push({position, name: f.name, size: f.size, status: '', err: [], file: f});
    this.filesDataStream.next(this.data);
    this.incr++;
  }

  removeFileFromList(index) {
    this.data.splice(index, 1);
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].position = i;
    }
    this.filesDataStream.next(this.data);
  }

  async uploadFiles() {
    for (const d of this.data) {
      d.status = 'Загрузка...';
      if (d.file !== null && d.status !== 'Загружено') {
        await this.uploadFile_(d.file).then(
          () => {
            d.status = 'Загружено';
          }).catch((error) => {
            console.log(error);
          }
        );
      }
    }
  }

  uploadFile_(f: File): Promise<any> {
    const fd: FormData = new FormData();
    fd.append('file', f, f.name);
    return this.request.post_('/validator/upload', fd);
  }

  reloadFiles(): Promise<void> {
    this.clearFiles();
    return this.request.get_('/validator/list').then(
      (res) => {
        const x = this;
        res.list.forEach(function (v) {
          x.data.push({position: x.incr, name: v.name, size: v.size, status: 'Загружено', err: [], file: null});
          x.filesDataStream.next(x.data);
          x.incr++;
        });
      }).catch((err) => {
        console.error('FilesService.reloadFiles:', err.toString());
      }
    );
  }

  removeFiles(): Promise<void> {
    return this.request.delete_('/validator/clear')
      .then(() => {
      }).catch((err) => {
        console.error('FilesService.removeFiles:', err.toString());
      });
  }

  clearFiles() {
    this.data = [];
    this.incr = 0;
    this.filesDataStream.next();
  }

///////////////////////////////////////////////////////////////////////////////////////////////

  async processFile() {
    await this.removeFiles();

    await this.uploadFiles();

    await this.reloadFiles();

    const x = this;
    this.data.forEach(function (v, k) {
      x.data[k].status = '';
      x.data[k].err = [];
    });

    for (const d of this.data) {
      d.status = 'Обработка...';

      await this.request.get_('/validator/valid/' + d.name).then((res) => {
        if (res.errors) {
          res.errors.forEach(function (v) {
            d.err.push(v);
          });
        }
      }).catch((err) => {
        console.error('FilesService.processFile_:', err.toString());
      });
      d.status = 'Проверено';
    }

    await this.dropSession();
  }

  dropSession(): Promise<void> {
    return this.request.get_('/validator/session/drop').then((res) => {
      // console.error(res);
    }).catch((err) => {
      // fixme валим в ошибку, поскольку с сервера я ничего не возвращаю, а мы ожидаем структуру по умолчанию для RequestService
      // console.error(err);
    });
  }

}
