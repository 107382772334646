import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RequestService {
  constructor(private http: HttpClient) {
  }

  async get_(url: string): Promise<any> {
    let res: any;
    try {
      res = await this.http.get(url).toPromise();
    } catch (err) {
      console.error('RequestService.get:', err.status, err.error);
      if (err.status === 403) {
        window.location.href = '/auth';
        return;
      }
      return;
    }
    if (res.data.error) {
      console.error(res.data.status, res.data.error);
    }
    return res.data;
  }

  async put_(url: string, body: any): Promise<boolean> {
    let res: any;
    try {
      res = await this.http.put(url, body).toPromise();
    } catch (err) {
      console.error('RequestService.put:', err.status, err.error);
      if (err.status === 403) {
        window.location.href = '/auth';
        return false;
      }
      return false;
    }
    return true;
  }

  async post_(url: string, body: any): Promise<number> {
    let res: any;
    try {
      res = await this.http.post(url, body).toPromise();
    } catch (err) {
      console.error('RequestService.push:', err.status, err.error);
      if (err.status === 403) {
        window.location.href = '/auth';
        return;
      }
      return;
    }
    return res.data;
  }

  async delete_(url: string): Promise<boolean> {
    let res: any;
    try {
      res = await this.http.delete(url).toPromise();
    } catch (err) {
      console.error('RequestService.delete:', err.status, err.error);
      if (err.status === 403) {
        window.location.href = '/auth';
        return false;
      }
      return false;
    }
    return true;
  }
}
