import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {FilesService} from '../files.service';

@Component({
  selector: 'app-files-validation',
  templateUrl: './files-validation.component.html',
  styleUrls: ['./files-validation.component.scss']
})
export class FilesValidationComponent implements OnInit {
  @ViewChild('filesInput') filesInput: ElementRef;

  isDisabledAdd: boolean;
  isEmptyList: boolean;
  isProcessing: boolean;

  constructor (
    private hostElement: ElementRef,
    private filesService: FilesService
  ) {
    this.isDisabledAdd = false;
    this.isEmptyList = true;
    this.isProcessing = false;
  }

  ngOnInit() {
    this.filesService.dataStream
      .subscribe(data => {
        this.isEmptyList = !(data && data.length);
      });
  }

  onAddFile() {
    this.filesInput.nativeElement.click();
  }

  onResetList() {
    this.filesService.clearFiles();
    this.isEmptyList = true;
  }

  onSelectFile() {
    const files = this.filesInput.nativeElement.files;

    for (const index in files) {
      if (files.hasOwnProperty(index)) {
        this.filesService.addFileToList(files[index]);
      }
    }

    this.isEmptyList = !Boolean(this.filesService.getData().length);
  }

  onRunValidation() {
    this.isProcessing = true;
    this.filesService.processFile()
      .finally(() => {
        this.isProcessing = false;
      });
  }
}
