import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FilesService} from '../files.service';
import {MatTableDataSource} from '@angular/material';
import {AuthService} from '../auth/auth.service';
import {MatDialog} from '@angular/material';
import {FileErrorsModalComponent} from './file-errors-modal/file-errors-modal.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})

export class FileListComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'status', 'err', 'delete'];
  dataSource = new MatTableDataSource();

  @Output() outProcess = new EventEmitter<boolean>();

  constructor(public filesService: FilesService,
    private authService: AuthService,
    public dialog: MatDialog) {
    if (!this.authService.isLoggedIn()) {
      this.filesService.clearFiles();
      return;
    }
    this.dataSource.data = filesService.getData();
  }

  openDialog(errors) {
    this.dialog.open(FileErrorsModalComponent, {data: errors});
  }

  ngOnInit() {
    this.filesService.dataStream
      .subscribe(data => {
        this.dataSource.data = data;
      });
  }

  removeFile(index) {
    this.filesService.removeFileFromList(index);
  }
}
