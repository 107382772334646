import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {AuthComponent} from './auth/auth.component';
import {FilesValidationComponent} from './files-validation/files-validation.component';

const routes: Routes = [
  {path: 'auth', component: AuthComponent},
  {
    path: 'validation',
    component: FilesValidationComponent,
    canActivate: [AuthGuard]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
